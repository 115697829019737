$base: 10px;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;
$base05: $base * 0.5;
$base025: $base * 0.25;

$baseHalf: $base * 0.5;

$maxTextWidth: 550px;
$maxWidth: 1200px;