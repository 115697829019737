*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

svg,
svg use {
  box-sizing: content-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ol {
  padding: 0;
}

dl {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  background: none;
  border: none;
  border-radius: 0;
  color: inherit;
  font-family: inherit;
  line-height: inherit;
  outline: 0;
  padding: 0;
}

p {
  margin: 0;
}

label {
  font-size: inherit;
}

input::-ms-clear {
  display: none;
}

input:not([type=checkbox]),
label,
textarea {
  font-family: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;

  &:invalid {
    outline: 0;
    box-shadow: none;
  }
}

figure {
  margin: 0;
}

[data-type="svg"] {
  display: inline-block;
  position: relative;


  &>canvas {
    visibility: hidden;
    display: block;
    width: inherit;
    height: inherit;
  }


  &>svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}

// background-image in img (using inline style) is used as a fallback to implement
// object-fit cover without JS for IE
// so we need to reset the background image with !important to override the inline style
// for all the other browsers
img {
  @supports (object-fit: cover) {
    background-image: none !important; // stylelint-disable-line
  }
}


@page {
  margin: 1.5cm;
}


@media (prefers-reduced-motion: reduce) {

  *,
  *::before,
  *::after {
    transition-duration: 0s !important; // stylelint-disable-line
    transition-delay: 0s !important; // stylelint-disable-line
    animation-duration: 0s !important; // stylelint-disable-line
    animation-delay: 0s !important; // stylelint-disable-line
  }
}